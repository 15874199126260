/* header categories ------- */
.hero-header-rounded~.header-categories {
    margin-top: -90px;
    padding: 12px;
}

.header-cat-box:hover .badge {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.header-cat-box:hover .badge {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white) !important;
}

@media(min-width: 576px) {
    .hero-header-rounded~.header-categories {
        padding: 0;
    }
}

.box-cat-size{
    box-sizing: initial;
}
.flex-grow-1.fs-16.fw-medium.ms-3 {
    text-align: left;
}