.cars1 {
    position: relative;
}
.cars1 .cars1-carousel {
    position: relative;
    display: block;
    max-width: 800px;
    margin: 0 auto;
}
.cars1 .cars1-carousel.owl-carousel .owl-stage-outer {
    overflow: visible;
    padding: 0px 0px 0px;
}
.cars1 .item {
    position: relative;
    display: block;
    margin-bottom: 15px;
}
.cars1 .item .img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    border-radius: 20px;

    width: 100%;
    transform: scale(1);
    transition: transform 500ms ease;
}


.cars1 .item:hover img {
    transform: scale(1.05);
}
.cars1 .item .con {
    position: relative;
    display: block;
    background-color: #f2f2f2;
    padding: 30px 25px;
    margin-top: -55px;
    margin-left: 25px;
    margin-right: 25px;
    overflow: hidden;
    transition: all 500ms ease;
    z-index: 2;
    visibility: visible;
    opacity: 0;
    border-radius: 20px;
}
.cars1 .item .con.opacity-1 {
    opacity: 0;
}
.cars1 .item .con.active{
    visibility: visible;
    opacity: 1;
}
.cars1 .item .con .title,
.cars1 .item .con .title a {
    font-family: 'Outfit', sans-serif;
    font-size: 21px;
    margin-bottom: 0px;
    color: #1b1b1b;
    font-weight: 700;
    line-height: 1.2em;
    transition: all 500ms ease;
}
.cars1 .item .con .details {
    font-size: 14px;
    color: #555;
    margin-bottom: 0;
    margin-right: 10px;
    transition: all 500ms ease;
}
.cars1 .item .con .details span {
    margin-right: 10px;
}
.cars1 .item .con .details span i {
    color: #f5b754;
    margin-right: 2px;
    font-style: normal;
}
/* book */
.cars1 .item .con .book {
    text-align: -webkit-right;
    margin-top: 1px;
}
.cars1 .item .con .book .btn {
    margin: 0;
    background: #f5b754;
    border: 1px solid #f5b754;
    padding: 14px 21px;
    color: #1b1b1b;
    border-radius: 30px;
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
    font-size: 14px;
}
.cars1 .item .con .book .btn:hover {
    background: #1b1b1b;
    color: #fff;
    border: 1px solid #1b1b1b;
}
.cars1 .item .con .book > div {
    display: table-cell;
    vertical-align: middle;
}
.cars1 .item .con .book > div:nth-child(2) {
    padding-left: 15px;
    padding-right: 0;
    font-size: 14px;
    line-height: 1.2em;
}
.cars1 .item .con .book > div > span {
    display: block;
    margin-bottom: 0;
    font-size: 14px;
    color: #555;
}
.cars1 .item .con .book .price {
    font-family: 'Outfit', sans-serif;
    font-size: 21px;
    line-height: 1.2em;
    color: #f5b754;
    font-weight: 700;
}
/* owl nav */
.owl-item.active .item .con {
    visibility: visible;
    opacity: 1;
}
.cars1 .owl-theme .owl-nav {
    position: absolute !important;
    top: 45% !important;
    bottom: auto !important;
    width: 100%
}
.cars1 .owl-theme .owl-nav {
    position: relative;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.cars1 .owl-theme .owl-prev,
.cars1 .owl-theme .owl-prev {
    left: 10px !important
}
.cars1 .owl-theme .owl-next {
    right: 10px !important
}
.cars1 .owl-theme .owl-prev,
.cars1 .owl-theme .owl-next {
    color: #fff;
    position: absolute !important;
    top: 50%;
    padding: 0;
    height: 50px;
    width: 50px;
    border-radius: 0%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    text-align: center;
    font-size: 14px
}
.cars1 .owl-theme .owl-prev > span,
.cars1 .owl-theme .owl-next > span {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}
.cars1 .owl-carousel .owl-nav button.owl-next,
.cars1 .owl-carousel .owl-nav button.owl-prev {
    outline: none;
}
.cars1 .owl-theme .owl-nav [class*=owl-] {
    width: 50px;
    height: 50px;
    line-height: 53px;
    background: #f5b754;
    border-radius: 100%;
    color: #1b1b1b;
    font-size: 10px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease-in-out;
    transform: scale(1.0);
}
.cars1 .owl-theme .owl-nav [class*=owl-]:hover {
    background: #1b1b1b;
    color: #fff;
}
.cars1 .owl-theme .owl-nav {
    top: 35% !important;
}
.cars1 .owl-theme .owl-nav [class*=owl-] {
    opacity: 0;
}
.cars1 .owl-theme:hover .owl-nav [class*=owl-] {
    opacity: 1;
}
@media screen and (max-width: 991px) {
    .cars1 .item .con {
        padding: 30px 20px;
        margin: 0;
    }
    .order2 {
        -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }
    .order1 {
        -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }
    .cars1 .item .con .book {
        text-align: left;
        margin-top: 3px;
    }
    .cars1-carousel {
        overflow: hidden;
    }
    .cars1 .owl-theme .owl-nav {
        display: none;
    }
}
