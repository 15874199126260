
.fullwidth-home-slider .item,
.fullwidth-property-slider .item,
.property-slider .item {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: 50%;
    border-radius: 3px;
}

.fullwidth-home-slider,
.fullwidth-property-slider {
    background-color: #fff;
}

.fullwidth-home-slider .item,
.fullwidth-property-slider .item {
    opacity: 0.6;
    transition: all 0.6s;
    border-radius: 0;
    position: relative;
    height: 600px;
}

.fullwidth-home-slider .item.slick-center,
.fullwidth-property-slider .item.slick-center { opacity: 1; }


.fullwidth-property-slider .slick-prev { left: 15%; }
.fullwidth-property-slider .slick-next { right: 15%; }

@media (max-width: 1440px) {
    .fullwidth-home-slider .item,
    .fullwidth-property-slider .item { height: 500px; }
}

@media (max-width: 1366px) {
    .fullwidth-property-slider .slick-prev { left: 9%; }
    .fullwidth-property-slider .slick-next { right: 9%; }
}
.slick-active.slick-current  .item.mfp-gallery {
    opacity: 1;
}
.slick-next:before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='15px'  version='1.1' id='Capa_1' viewBox='0 0 223.413 223.413' xml:space='preserve'><g><g><g><polygon style='fill:%23ffffff;' points='57.179,223.413 51.224,217.276 159.925,111.71 51.224,6.127 57.179,0 172.189,111.71'/></g></g></g></svg>");
    right: -1px;
    z-index: 2;

}
.slick-prev:before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='15px'  version='1.1' id='Capa_1' viewBox='0 0 197.402 197.402' xml:space='preserve'><g><g><g><polygon style='fill:%23ffffff;' points='146.883,197.402 45.255,98.698 146.883,0 152.148,5.418 56.109,98.698 152.148,191.98'/></g></g></g></svg>");
    right: -1px;
    z-index: 2;

}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 45%;
    z-index: 990;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
}
.slick-next:after, .slick-prev:after {
    background-color: #f84525;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    content: "";
    opacity: 0.85;
    transition: all 0.35s;
}