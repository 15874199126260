.ff_fileupload_preview_image{
    display: block;
    box-sizing: border-box;
    border: 0 none;
    padding: 0;
    background-color: #DDDDDD;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    opacity: 0.75;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #222222;
    overflow: hidden;
    outline: none;
    cursor: default;
}
.ff_fileupload_uploads {
    width: 100%;
    border-collapse: collapse !important;
    border: 0 none;
}
.ff_fileupload_wrap table.ff_fileupload_uploads {
    width: 100%;
    border-collapse: collapse !important;
    border: 0 none;
}
.ff_fileupload_filename {
    width: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
button.ff_fileupload_remove_file {
    border: none;
    width: min-content;
    border-radius: 0.4rem;
    background-color: rgba(222, 226, 230, 0.3);
}
.ff_fileupload_actions {
    width: 1px;
    text-align: right;
}
.ff_fileupload_uploads td {
    vertical-align: top;
    padding: 1em 0;
    white-space: nowrap;
    line-height: normal;
}

.ff_fileupload_summary {
    padding: 1.1em;
    line-height: normal;
    vertical-align: top;
    font-size: 0.9em;
    white-space: normal;

}