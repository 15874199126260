/* ======= Header style ======= */
.hero-header-waves.dark-overlay::after {
    z-index: 1;
}
.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover;
}
.dark-overlay .overlay-content {
    position: relative;
    z-index: 1;
}
.dark-overlay::after {
    position: absolute;
    opacity: 0.5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: #000;
}
.hero-header-rounded {
    width: 100%!important;
    margin: 0 !important;
}
.align-items-center.d-flex.hero-header-rounded.dark-overlay.mx-3.overflow-hidden.position-relative.rounded-4 {
    border-top-left-radius: 0% !important;
    border-top-right-radius: 0% !important;
}
.hero-header.dark-overlay::after {
    opacity: 0.4;
}

.hero-header-subtitle {
    letter-spacing: .1em;
}

.hero-header-map {
    height: 500px;
}

.hero-header-rounded {
    padding: 6rem 0 11.625rem;
}

.hero-header-grid {
    padding: 3rem 0;
}

@media(max-width: 767px) {
    .hero-header {
        height: auto !important;
    }
}

@media(min-width: 576px) {
    .hero-header-grid {
        padding: 5rem 0;
    }

    .hero-header-map {
        height: 600px;
    }
}

@media(min-width: 768px) {
    .hero-header {
        padding: 12rem 0;
    }

    .hero-header-grid {
        padding: 8rem 0;
    }

    .hero-header-rounded {
        padding: 12rem 0 17.625rem;
    }

    .hero-header-map {
        height: 800px;
    }
}

@media(min-width: 992px) {
    .hero-header-grid {
        padding: 12rem 0;
    }
}

@media(min-width: 1200px) {
    .hero-header.vh-100 {
        height: calc(100vh - 32px) !important;
    }

    .hero-header-rounded .bg-image {
        border-top-left-radius: 0%;
        border-bottom-left-radius: 22%;
        border-bottom-right-radius: 60%;
    }

    .hero-header-rounded.dark-overlay::after {
        border-top-left-radius: 0%;
        border-bottom-left-radius: 22%;
        border-bottom-right-radius: 60%;
    }
}

.hero-header-classic {
    padding: 10rem 0 4.5rem;
}

@media (min-width: 768px) {
    .hero-header-classic {
        padding: 25rem 0 8rem;
    }
}

.bg-image-overlay::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: rgba(25, 30, 37, 0.25);
}

.bg-gradient-vertical {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(#191e25), to(rgba(25, 30, 37, 0)));
}

.header-carousel.owl-carousel .owl-stage-outer,
.header-carousel.owl-carousel .owl-stage,
.header-carousel.owl-carousel.owl-drag .owl-item {
    height: 100%;
}

.hero-header-waves.dark-overlay::after {
    z-index: 1;
}

/* Header search content ---- */
.search-content {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, .5);
}

[data-bs-theme=dark] .search-content {
    background-color: rgba(25, 30, 37, .5);
}

.search-wrapper {
    grid-column-gap: 24px;
    padding: 10px 10px 10px 24px;
    -webkit-box-shadow: 0 3px 20px rgba(192, 192, 192, .21);
    box-shadow: 0 3px 20px rgba(192, 192, 192, .21);
    border-radius: 50px;
}

@media screen and (max-width: 767px) {
    .search-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border-radius: 1rem;
    }
}

.search-field {
    grid-column-gap: 16px;
}

.svg-icon {
    width: 24px;
    height: 24px;
    color: var(--bs-dark);
}

[data-bs-theme=dark] .svg-icon {
    color: var(--bs-white);
}

.search-input,
.search-select-field {
    padding: 10px 24px 10px 0;
    border-radius: 0;
    border-width: 0 0 1px 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.vertical-divider {
    border-left: 1px solid #d6ddeb;
}

.vertical-divider {
    border-left-color: rgba(255, 255, 255, .1);
}

.search-input:focus,
.search-select-field:focus {
    border-color: var(--bs-primary);
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* header categories ------- */
.hero-header-rounded~.header-categories {
    margin-top: -90px;
    padding: 12px;
}

.header-cat-box:hover .badge {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.header-cat-box:hover .badge {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white) !important;
}

@media(min-width: 576px) {
    .hero-header-rounded~.header-categories {
        padding: 0;
    }
}
.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-cap-bg: transform;
    --bs-card-border-radius: var(--bs-border-radius-xl);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius-xl) -(var(--bs-border-width)));
}
.text-span {
    background-image: url("data:image/svg+xml,%3Csvg width='262' height='17' viewBox='0 0 262 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99931 12.9463C70.3926 7.14883 214.09 -1.29457 257.733 11.3115' stroke='%23F84525' stroke-width='8' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: auto;
}

.inner-header {
    padding: 5rem 0;
}

.navbar-fixed~.hero-header-waves .inner-header {
    padding-top: 9.813rem;
}

@media(min-width: 768px) {
    .inner-header {
        padding: 12rem 0;
    }

    .navbar-fixed~.hero-header-waves .inner-header {
        padding-top: 16.813rem;
    }
}