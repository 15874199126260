.navbar {
    --bs-navbar-padding-y: 1rem;
    --bs-navbar-collapse-border-color: var(--bs-border-color);
}

@media(max-width: 991px) {
    [dir=rtl] .navbar {
        overflow: hidden;
    }
}

@media(max-width: 576px) {

    .navbar .container,
    .navbar .container-fluid,
    .navbar .container-lg,
    .navbar .container-md,
    .navbar .container-sm,
    .navbar .container-xl,
    .navbar .container-xxl {
        --bs-gutter-x: 2rem;
    }
}

.custom-navbar.navbar {
    border-radius: var(--bs-border-radius-lg);
}

.navbar {
    z-index: 9;
    --bs-navbar-color: #2f2e41;
    --bs-navbar-nav-link-padding-x: 0.7rem;
    --bs-navbar-active-color: var(--bs-primary);
    --bs-navbar-hover-color: var(--bs-primary);
    --bs-navbar-border-color: var(--bs-primary);
    background-color: var(--bs-white);
}

[data-bs-theme=dark] .navbar {
    background-color: var(--bs-body-bg);
}

@media(max-width: 991px) {
    .custom-navbar.navbar.navbar-transfarent {
        border-radius: .6rem;
    }
}

.custom-navbar.navbar.navbar-fixed {
    top: 16px;
    left: 0;
    z-index: 1030;
    position: fixed;
    width: calc(100% - 32px);
    -webkit-transition: width .15s cubic-bezier(.25, 1, .5, 1) 0s, padding .45s cubic-bezier(.25, 1, .5, 1) .1s;
    -o-transition: width .15s cubic-bezier(.25, 1, .5, 1) 0s, padding .45s cubic-bezier(.25, 1, .5, 1) .1s;
    transition: width .15s cubic-bezier(.25, 1, .5, 1) 0s, padding .45s cubic-bezier(.25, 1, .5, 1) .1s;
    -webkit-transform: translate(16px, 0);
    -ms-transform: translate(16px, 0);
    transform: translate(16px, 0);
}

.custom-navbar.navbar.navbar-transfarent {
    background-color: transparent
}

.custom-navbar.navbar.navbar-bg {
    -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

@media(max-width: 991px) {
    .custom-navbar.navbar {
        --bs-navbar-padding-x: .5rem;
    }

    .nav-container {
        padding: 0;
    }

    .navbar-nav {
        margin-top: 1rem;
    }

}

@media(min-width: 992px) and (max-width: 1199px) {
    .navbar {
        --bs-navbar-nav-link-padding-x: 0.5rem;
    }
}

@media(min-width: 992px) {

    .custom-navbar.navbar {
        border-radius: var(--bs-border-radius-xl);
    }

    .navbar {
        padding: 0;
        --bs-navbar-color: #2c3038;
    }

    [data-bs-theme=dark] .navbar {
        --bs-navbar-color: var(--bs-white);
    }

    .custom-navbar.navbar.navbar-transfarent {
        --bs-navbar-color: var(--bs-white);
        --bs-navbar-active-color: var(--bs-white);
        --bs-navbar-border-color: var(--bs-white);
    }
}

.navbar .navbar-nav {
    --bs-nav-link-padding-y: 1.7rem;
}