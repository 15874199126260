.flex-fill {
    flex: 1 1 auto !important;
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important;
}
.card-img-wrap {
    height: 0;
    padding-bottom: 65%;
    overflow: hidden;
    position: relative;
}

.card-image-hover::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.card-hover-bg:hover .card-image-hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.card-img-wrap {
    height: 0;
    padding-bottom: 65%;
    overflow: hidden;
    position: relative;
}

/* Card badge ------ */
.card-badge {
    top: 25px;
    padding: 5px 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    letter-spacing: 1px;
    font-size: 0.719rem !important;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
}

[dir=rtl] .card-badge {
    border-radius: 6px 0 0 6px;
}

.card-badge+.card-badge {
    top: 60px;
}

.card-start {
    font-size: 14px;
}

.cat-icon {
    width: 52px;
    height: 52px;
    top: -25px;
    right: 24px;
    border: 2px solid #fff;
}

[dir=rtl] .cat-icon {
    right: auto;
    left: 24px;
}

.btn-icon {
    height: 36px;
    width: 36px;
}

.btn-icon-md {
    height: 42px;
    width: 42px;
}

.card.dark-overlay::after {
    opacity: 1;
    background: -o-linear-gradient(bottom, rgba(25, 30, 37, .9) 0%, rgba(25, 30, 37, .55) 35%, rgba(22, 22, 23, .1) 60%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(25, 30, 37, .9)), color-stop(35%, rgba(25, 30, 37, .55)), color-stop(60%, rgba(22, 22, 23, .1)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(25, 30, 37, .9) 0%, rgba(25, 30, 37, .55) 35%, rgba(22, 22, 23, .1) 60%, rgba(0, 0, 0, 0) 100%);
}
