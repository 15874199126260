.mb-5 {
    margin-bottom: 3rem !important;
}
.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-cap-bg: transform;
    --bs-card-border-radius: var(--bs-border-radius-xl);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius-xl) - (var(--bs-border-width)));
}

.card-header,
.card-footer {
    width: calc(100% - var(--bs-card-spacer-x)*2);
    padding-right: 0;
    padding-left: 0;
    margin: auto;
}

/* card hover */
.card-hover {
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.card-hover:hover {
    -webkit-transform: translate(0px, -6px);
    -ms-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
}
.company-logo {
    height: 50px;
}
.brand-button-centre {
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    justify-content: center !important;
    align-items: center !important;
}
